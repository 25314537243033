.production-tree .ant-menu-item-group-title {
  display: none;
}

.production-tree span.ant-tree-switcher.ant-tree-switcher-noop {
  display: none;
}

.production-tree.ant-tree .ant-tree-node-content-wrapper {
  min-width: 400px;
}

.production-tree .ant-tree-title h3 {
  height: 50px;
  line-height: 50px;
  margin-bottom: 0;
}

span.edit-cue {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 4px;
  display: block;
}

span.edit-icon {
  padding-left: 10px;
}

.production-tree
  .ant-tree-list-holder-inner
  .ant-tree-treenode-leaf-last
  .ant-tree-node-content-wrapper {
  min-width: initial;
}

audio.react-audio-player:focus {
  outline: none;
}

.ant-picker-dropdown,
.ant-select-dropdown {
  pointer-events: all !important;
}
